import React, { FC, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'

const InventoryAddSet: FC = () => {
  const [itemData, setItemData] = useState({});

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log('Submitting inventory data:', itemData);
    // Handle submit logic here
  };

  const handleChange = (e: any) => {
    setItemData({ ...itemData, [e.target.name]: e.target.value });
  };

  return (
    <div className='modal fade' id='kt_modal_inventory_add_set' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <form onSubmit={handleSubmit}>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Add Inventory Item</h1>
              </div>

              <div className='mb-8'>
                <label htmlFor='set_number' className='form-label'>Set Number</label>
                <input
                  id='set_number'
                  name='set_number'
                  type='text'
                  className='form-control form-control-solid'
                  onChange={handleChange}
                />
              </div>

              <div className='mb-8'>
                <label htmlFor='set_name' className='form-label'>Set Name</label>
                <input
                  id='set_name'
                  name='set_name'
                  type='text'
                  className='form-control form-control-solid'
                  onChange={handleChange}
                />
              </div>

              <div className='mb-8'>
                <label htmlFor='theme' className='form-label'>Theme</label>
                <input
                  id='theme'
                  name='theme'
                  type='text'
                  className='form-control form-control-solid'
                  onChange={handleChange}
                />
              </div>

              <div className='mb-8'>
                <label htmlFor='condition' className='form-label'>Condition</label>
                <input
                  id='condition'
                  name='condition'
                  type='text'
                  className='form-control form-control-solid'
                  onChange={handleChange}
                />
              </div>

              <div className='mb-8'>
                <label htmlFor='date_purchased' className='form-label'>Date Purchased</label>
                <input
                  id='date_purchased'
                  name='date_purchased'
                  type='date'
                  className='form-control form-control-solid'
                  onChange={handleChange}
                />
              </div>

              <div className='mb-8'>
                <label htmlFor='price_paid' className='form-label'>Price Paid</label>
                <input
                  id='price_paid'
                  name='price_paid'
                  type='number'
                  className='form-control form-control-solid'
                  onChange={handleChange}
                />
              </div>

              <div className='mb-8'>
                <label htmlFor='tax_paid' className='form-label'>Tax Paid</label>
                <input
                  id='tax_paid'
                  name='tax_paid'
                  type='number'
                  className='form-control form-control-solid'
                  onChange={handleChange}
                  />
              </div>
              <div className='mb-8'>
            <label htmlFor='shipping_paid' className='form-label'>Shipping Paid</label>
            <input
              id='shipping_paid'
              name='shipping_paid'
              type='number'
              className='form-control form-control-solid'
              onChange={handleChange}
            />
          </div>

          <div className='mb-8'>
            <label htmlFor='vendor' className='form-label'>Vendor</label>
            <input
              id='vendor'
              name='vendor'
              type='text'
              className='form-control form-control-solid'
              onChange={handleChange}
            />
          </div>

          <div className='mb-8'>
            <label htmlFor='storage_location' className='form-label'>Storage Location</label>
            <input
              id='storage_location'
              name='storage_location'
              type='text'
              className='form-control form-control-solid'
              onChange={handleChange}
            />
          </div>

          <div className='mb-8'>
            <label htmlFor='notes' className='form-label'>Notes</label>
            <textarea
              id='notes'
              name='notes'
              className='form-control form-control-solid'
              rows={3}
              onChange={handleChange}
            ></textarea>
          </div>

          <div className='d-flex justify-content-end'>
            <button type='submit' className='btn btn-primary'>Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
  )
}

export { InventoryAddSet }
