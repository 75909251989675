import React, { FC, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'

const CatalogViewSet: FC = () => {

  return (
    <div className='modal fade' id='kt_modal_catalog_view_set' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button type='submit' className='btn btn-primary'>Submit</button>
          </div>
      </div>
    </div>
  </div>
  )
}

export { CatalogViewSet }
