import React, {UIEvent, useMemo, useEffect, useState, useRef, useCallback} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  type MRT_RowVirtualizer,
  useMaterialReactTable,
} from 'material-react-table'
import {IconButton, Tooltip} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import {QueryClient, QueryClientProvider, useInfiniteQuery} from '@tanstack/react-query'
import {Box, Typography} from '@mui/material'
//Date Picker Imports
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import {DatePickerInput, DatesRangeValue} from '@mantine/dates'

interface CatalogMinifiguresTableProps {
  className?: string // Make it optional
}

interface MinifiguresCatalogProps {
  className?: string // Make it optional
}

type MinifiguresApiResponse = {
  data: Array<Minifigures>
  meta: {
    totalRowCount: number
  }
}

type Minifigures = {
  fig_num: string
  name: string
  img_url: string
  num_parts: number
}

const MinifiguresCatalog: React.FC<MinifiguresCatalogProps> = ({className}) => {
  const columns = useMemo<MRT_ColumnDef<Minifigures>[]>(
    () => [
      {
        accessorKey: 'img_url',
        header: 'Thumbnail',
        size: 150,
        enableColumnFilter: false,
        Cell: ({cell}) => {
          const imgUrl = cell.getValue<string>()

          return (
            <div>
              {/* Thumbnail image with tooltip trigger */}
              <img data-tip data-for={`tooltip-${imgUrl}`} src={imgUrl} height={50} />

              {/* Tooltip showing the larger image */}
              <ReactTooltip id={`tooltip-${imgUrl}`} data-tooltip-content='${imgUrl}'>
                <img src={imgUrl} alt='Enlarged' width={300} />
              </ReactTooltip>
            </div>
          )
        },
      },
      {
        accessorKey: 'fig_num',
        header: 'Minifigure',
        size: 150,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
      },
      {
        accessorKey: 'num_parts',
        header: 'Pieces',
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        size: 150,
        muiFilterSliderProps: {
          marks: true,
          max: 50, //custom max (as opposed to faceted max)
          min: 0, //custom min (as opposed to faceted min)
          step: 1,
        },
      },
    ],
    []
  )
  const tableContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
  const rowVirtualizerInstanceRef =
    useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null) //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, minifigureSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })
  const [rowCount, setRowCount] = useState(0)
  const {data, fetchNextPage, isError, isFetching, isLoading, refetch} =
    useInfiniteQuery<MinifiguresApiResponse>({
      queryKey: [
        'table-data',
        columnFilters, //refetch when columnFilters changes
        globalFilter, //refetch when globalFilter changes
        pagination.pageIndex, //refetch when pagination.pageIndex changes
        pagination.pageSize, //refetch when pagination.pageSize changes
        sorting, //refetch when sorting changes
      ],
      queryFn: async ({pageParam = 0}) => {
        const fetchURL = new URL(
          '/minifigures/',
          process.env.NODE_ENV === 'production'
            ? `${process.env.REACT_APP_API_URL}`
            : 'http://localhost:3000'
        )
        fetchURL.searchParams.set('page', `${pageParam + 1}`) // +1 because pageParam starts from 0 for the first page
        fetchURL.searchParams.set('size', `${pagination.pageSize}`)
        fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []))
        fetchURL.searchParams.set('search', globalFilter ?? '')
        fetchURL.searchParams.set('sort', JSON.stringify(sorting ?? []))

        const response = await fetch(fetchURL.href)
        const json = (await response.json()) as MinifiguresApiResponse
        console.log('Fetched Data:', json)
        return json
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    })

  const flatData = useMemo(() => data?.pages.flatMap((page) => page.data) ?? [], [data])
  console.log(data)
  const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0
  const totalFetched = flatData.length

  const MinifiguresCatalogTable = useMaterialReactTable({
    columns,
    data: flatData,
    enableEditing: false,
    editDisplayMode: 'modal',
    enableClickToCopy: true,

    enableRowSelection: true,
    enableRowVirtualization: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableRowPinning: false,
    enableRowActions: true,
    rowVirtualizerInstanceRef: rowVirtualizerInstanceRef, //get access to the virtualizer instance
    rowVirtualizerOptions: {overscan: 10},   
    initialState: {
      showColumnFilters: true,
      // columnPinning: { left: ['set_number'] },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        muiTableHeadCellProps: {
          align: 'center', //change head cell props
        },
      },
      'mrt-row-numbers': {
        enableColumnOrdering: true, //turn on some features that are usually off
        enableResizing: true,
        muiTableHeadCellProps: {
          sx: {
            fontSize: '1.2rem',
          },
        },
      },
    },
    // renderDetailPanel: ({ row }) => (
    //   <Box
    //     sx={{
    //       display: 'grid',
    //       margin: 'auto',
    //       gridTemplateColumns: '1fr 1fr',
    //       width: '100%',
    //     }}
    //   >
    //   <div>
    //       {/* <Typography>Pieces: {row.original.num_parts.toString()}</Typography> */}
    //       <Typography>Theme: {row.original.theme}</Typography>
    //     </div>
    //   </Box>),
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: {
      ref: tableContainerRef, //get access to the table container element
      sx: {maxHeight: '400px'}, //give the table a max height
      onScroll: (
        event: UIEvent<HTMLDivElement> //add an event listener to the table container element
      ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    muiToolbarAlertBannerProps: isError 
    ? {
        color: 'error',
        children: 'Error loading data',
      }
    : undefined,
    renderTopToolbarCustomActions:() => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    renderBottomToolbarCustomActions: () => (
      <Typography>
        Fetched {totalFetched} of {totalDBRowCount} total rows. Good boy! ˁ(⚆ᴥ⚆)ˀ
      </Typography>
    ),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
    },
    enablePagination: false,
    rowCount: rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: minifigureSorting,
    }
  );

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const {scrollHeight, scrollTop, clientHeight} = containerRefElement
        if (
          scrollHeight - scrollTop - clientHeight < 200 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          console.log('Fetching next page...')
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  )

  //scroll to top of table when sorting or filters change
  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0)
    } catch (error) {
      console.error(error)
    }
  }, [sorting, columnFilters, globalFilter])

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])


  return (
    <div className={`card ${className} w-100`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 w-100'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Catalog - Minifigures</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {data?.pages?.[0]?.meta?.totalRowCount ?? 0} Total Minifigure(s) in Selection
          </span>
        </h3>
        {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Member
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 w-100'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <MaterialReactTable
            table={MinifiguresCatalogTable}
          />
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const queryClient = new QueryClient()

const CatalogMinifiguresTable: React.FC<CatalogMinifiguresTableProps> = () => (
  <QueryClientProvider client={queryClient}>
    <MinifiguresCatalog /> {/* Pass className as a prop */}
  </QueryClientProvider>
)

export {CatalogMinifiguresTable}
