import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {
  confirmResetPassword,
} from 'aws-amplify/auth';

const initialValues = {
  confirmationCode: '',
  newPassword: '',
}

const confirmForgotPasswordSchema = Yup.object().shape({
  confirmationCode: Yup.number()
    .required('Confirmation code is required')
    .positive()
    .integer(),
  newPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const ConfirmForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: confirmForgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      try {
        const { email, goToLocation } = location.state as  { email: string, goToLocation: string };
        await confirmResetPassword({
          username: email,
          confirmationCode: values.confirmationCode,
          newPassword: values.newPassword
        });
        setHasErrors(false)
        setLoading(false)

        return navigate(goToLocation, { replace: true });
      } catch(e) {
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus('Something went wrong')
      }
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Confirm new password</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Enter the confirmation code sent to your email along with your new password.
        </div>
      </div>

      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors; please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sent password reset. Please check your email</div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
        <input
          type='password'
          placeholder='*****'
          autoComplete='off'
          {...formik.getFieldProps('newPassword')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
            {
              'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
            }
          )}
        />
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Confirmation code</label>
        <input
          type='text'
          placeholder='123456'
          autoComplete='off'
          {...formik.getFieldProps('confirmationCode')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.confirmationCode && formik.errors.confirmationCode},
            {
              'is-valid': formik.touched.confirmationCode && !formik.errors.confirmationCode,
            }
          )}
        />
        {formik.touched.confirmationCode && formik.errors.confirmationCode && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmationCode}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}

export default ConfirmForgotPassword;
