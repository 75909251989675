/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import { fetchAuthSession } from 'aws-amplify/auth'
import App from '../App'
import { getUserByToken } from '../modules/auth/core/_requests'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const { currentUser, setCurrentUser } = useAuth();

  const fetchUser = async () => {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      if(!currentUser && idToken) {
        const res = await getUserByToken(idToken?.toString());
        if(res && res.data) {
          const { user } = res.data;
          setCurrentUser(user);
        } else {
          setCurrentUser({
            id: "",
            username: "",
            password: undefined,
            email: "",
            first_name: "",
            last_name: "",
          });
        }
      }
    } catch(error) {
      return console.log({error})
    }
  }

  useEffect(() => {
    fetchUser();
  }, [])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
