import {
  FC,
  useState,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {scheduleTokenRefresh} from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import { signOut } from 'aws-amplify/auth';

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    try {
      await signOut();
      saveAuth(undefined)
      setCurrentUser(undefined)
    } catch(e) {
      // TODO change to an actual display error in UI 
      console.log({
        e
      })
    }
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S accessToken) before rendering the application
  // useEffect(() => {
  //   const requestUser = async (accessToken: string) => {
  //     try {
  //       if (!didRequest.current) {
  //         const {data} = await getUserByToken(accessToken)
  //         if (data) {
  //           setCurrentUser(data)
  //           scheduleTokenRefresh(auth); // Schedule the token refresh
  //         }
  //       }
        
  //     } catch (error) {
  //       if (!didRequest.current) {
  //         logout()
  //       }
  //     } finally {
  //       setShowSplashScreen(false)
  //     }

  //     return () => (didRequest.current = true)
  //   }

  //   if (auth && auth.accessToken) {
  //     requestUser(auth.accessToken)
  //   } else {
  //     logout()
  //     setShowSplashScreen(false)
  //   }
  //   // eslint-disable-next-line
  // }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
