// src/app/modules/auth/AutoLogout.ts
import React, { useEffect, useRef, useCallback } from 'react';
import { useAuth } from './core/Auth';

const AUTO_LOGOUT_TIME = 15 * 60 * 1000; // 15 minutes in milliseconds

export function useAutoLogout() {
  const { logout } = useAuth();
  const logoutTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetTimer = useCallback(() => {
    clearTimeout(logoutTimer.current as ReturnType<typeof setTimeout>);
    logoutTimer.current = setTimeout(logout, AUTO_LOGOUT_TIME);
  }, [logout]);

  useEffect(() => {
    resetTimer();

    // Add event listeners to detect user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('mousedown', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('touchmove', resetTimer);

    return () => {
      // Clean up event listeners
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('mousedown', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('touchmove', resetTimer);
    };
  }, [resetTimer]);

  return resetTimer;
}

export {};