import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'

import {useAutoLogout} from '../app/modules/auth/AutoLogout'
import {GoogleOAuthProvider} from '@react-oauth/google'

import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: `${process.env.REACT_APP_AWS_USER_POOLS_ID}`,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: `${process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID}`,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: `${process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL}`,
      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      allowGuestAccess: false,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'
    }
  }
});

const App = () => {
  useAutoLogout() // Use the auto-logout hook
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <GoogleOAuthProvider clientId='203914289973-u7af487fleqqtk4rda72eqvrbkfogi64.apps.googleusercontent.com'>
              <Outlet />
              <MasterInit />
            {/* <AuthInit>
            </AuthInit> */}
          </GoogleOAuthProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export default App;
