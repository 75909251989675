import axios, {AxiosRequestConfig} from 'axios'
import { fetchAuthSession } from 'aws-amplify/auth'

const {REACT_APP_API_URL} = process.env
let headers = {}

const callApi = axios.create({
  baseURL: REACT_APP_API_URL,
  headers,
})

callApi.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    if (idToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${idToken.toString()}`,
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

callApi.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      console.log("Enter here", response)
      resolve(response)
    }),
  async (error) => {
    const originalRequest = error.config;

    if(error) {
      console.log({
        error,
        originalRequest
      })
    }
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = false;
      
    //   try {
    //     const refreshToken = await localStorage.getItem('refreshToken'); // Make sure you store and retrieve the refresh token correctly.
    //     const response = await callApi.post('/auth/refresh', { token: refreshToken }); // Send the correct data to refresh the token.
        
    //     const newAccessToken = response.data.accessToken;
    //     localStorage.setItem('token', newAccessToken);
        
    //     originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
    //     return callApi(originalRequest);
    //   } catch (refreshError) {
    //     // Handle token refresh error or redirect to re-authentication
    //     // For example, log out the user or redirect to the login page
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('refreshToken');
    //     // Redirect to login or some other action
    //     return Promise.reject(refreshError);
    //   }
    // }

    // return Promise.reject(error);
  }
);


export default callApi
