import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush, ReferenceLine } from 'recharts';
import { parseISO } from 'date-fns';

type FormattedChartData = { x: Date; y: number; }[]; // Use Date for x

interface PhSetResponse {
    id: number;
    upc_barcode: string;
    type: string;
    timestamp: Date;
    price: number;
    additional_info: number;
}

function SetLineChart({ upc_barcode, type, date_retired }: { upc_barcode: string, type: string, date_retired?: Date | string | null }) {

    const [chartData, setChartData] = useState<FormattedChartData>([]);
    const [error, setError] = useState<string | null>(null);

    const retiredDateTimestamp = date_retired ? new Date(date_retired).getTime() : null;
    
    console.log("retiredDate (original):", date_retired);
    console.log("retiredDateTimestamp (converted):", retiredDateTimestamp);
    console.log("First chartData x value:", chartData[0]?.x);
    console.log("Last chartData x value:", chartData[chartData.length - 1]?.x);


    useEffect(() => {
        console.log("Making request to backend");

        // Construct the URL based on the environment
        const fetchURL = new URL(
            '/setsPh',
            process.env.NODE_ENV === 'production'
                ? `${process.env.REACT_APP_API_URL}`
                : 'http://localhost:3000'
        );
        fetchURL.searchParams.set('upc_barcode', upc_barcode);
        fetchURL.searchParams.set('type', type);

        fetch(fetchURL.toString())
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text().then(text => {
                    try {
                        return JSON.parse(text);
                    } catch (error) {
                        throw error;
                    }
                });
            })
            .then((responseData: { data: PhSetResponse[] }) => {
                const data = responseData.data;
            
                if (!Array.isArray(data)) {
                    throw new Error('Expected an array response, but received something else.');
                }
            
                const formattedData = formatDataForChart(data);
                console.log('Formatted Data:', formattedData); // Add this line
                setChartData(formattedData);
                setError(null);
             })                    
            .catch(error => {
                console.error("Error fetching data:", error);
                setError(error.message);
            });
    }, [upc_barcode, type]);

    function formatDataForChart(dataItems: PhSetResponse[]): FormattedChartData {
        return dataItems
            .filter(item => item.price !== 0 && item.price !== -1)
            .map(item => ({
                x: new Date(item.timestamp), // Parse as Date here
                y: item.price
            }));
    }

    return (
        <div style={{ height: '400px' }}>
            {error ? (
                <div>Error: {error}</div>
            ) : (
                <ResponsiveContainer width="100%" height="100%">
                   <LineChart
                        data={chartData}
                        margin={{ top: 0, right: 60, bottom: 50, left: 60 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            angle={-45}
                            height={70}
                            tickMargin={25}
                            tickFormatter={(dateStr: string | number | Date) => new Date(dateStr).toISOString().split('T')[0]}
                            dataKey="x"
                        />
                        <YAxis
                            tickFormatter={(tickItem: number) => `$${tickItem.toFixed(2)}`}
                            label={{ value: 'Price', angle: -90, position: 'insideLeft' }}
                            domain={[0, (dataMax: number) => Math.ceil(dataMax * 2)]}
                        />
                        <Tooltip />
                        <Legend verticalAlign="bottom" align="right" />
                        <Line
                            type="monotone"
                            dataKey="y"
                            stroke="#E71C23"
                            dot={{ r: 1 }}
                            activeDot={{ r: 5 }}
                        />
                        <Brush dataKey="x" height={30} stroke="#E71C23" />
                        
                        {
                        retiredDateTimestamp && !isNaN(retiredDateTimestamp) && (
                            <ReferenceLine
                                x={retiredDateTimestamp} // Use the parsed timestamp
                                label="Retired Date"
                                stroke="red"
                                ifOverflow="extendDomain"
                            />
                        )
                        }
                    </LineChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}

export default SetLineChart;
