import { PageTitle } from '../../../_metronic/layout/core';

export function ReleaseNotes() {
  return (
    <>
      {/* Page title */}
      <PageTitle breadcrumbs={[]}>Release Notes</PageTitle>

      {/* Single large content card */}
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-12">
          <div className="card h-100">
            <div className="card-body">
              <h3 className="card-title">Release Notes v1.0.0</h3>
              <p>Here is where the release notes for version 1.0.0 will go.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
