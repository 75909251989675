import {AuthModel} from './_models'
import {Logout} from '../Logout'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {GET_USER_BY_API_TOKEN_URL} from './_requests'
import { fetchAuthSession } from 'aws-amplify/auth';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    console.log('setAuth returned')
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const scheduleTokenRefresh = (auth: AuthModel | undefined) => {
  const refreshToken = getAuth(); // Implement this function in authHelper to get the refresh token from cookies or localStorage
  const API_URL = process.env.REACT_APP_API_URL
  // Refresh the token 5 minutes before it expires (assuming the access token expires in 15 minutes)
  const expiresInMilliseconds = 3 * 60 * 1000;
  console.log('Started refresh function...')
  const refreshTokenIfNeeded = async () => {
    try {
      const { data } = await axios.post(GET_USER_BY_API_TOKEN_URL, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (data.accessToken) {
        const newAuth = {
          ...auth,
          accessToken: data.accessToken,
        };
        setAuth(newAuth);
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      Logout();
    }

    setTimeout(refreshTokenIfNeeded, expiresInMilliseconds);
  };

  setTimeout(refreshTokenIfNeeded, expiresInMilliseconds);
};

export async function setupAxios(axios: any) {
  axios.defaults.headers.common['Accept'] = 'application/json';

  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const auth = getAuth();
      if (auth && auth.accessToken) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${auth.accessToken}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (err: any) => {
      const originalRequest = err.config;
      try {
        const { tokens } = await fetchAuthSession();

        if(tokens && tokens.accessToken) {
          setAuth({
            accessToken: tokens.accessToken.toString()
          });
          return axios(originalRequest);
        }
      } catch(err) {
        Promise.reject(err);
      }

      // if (err.response.status === 401 && !originalRequest._retry) {
      //   originalRequest._retry = true;
      //   const auth = getAuth();

      //   if (!auth || !auth.refreshToken) {
      //     // Handle missing auth or refreshToken, e.g. log the user out
      //     return Promise.reject(err);
      //   }

      //   try {
      //     const { data } = await axios.post('/auth/refresh', {
      //       refreshToken: auth.refreshToken,
      //     });

      //     auth.accessToken = data.accessToken;
      //     setAuth(auth);
      //     originalRequest.headers.Authorization = `Bearer ${auth.accessToken}`;

      //     return axios(originalRequest);
      //   } catch (err) {
      //     // Handle token refresh failure, e.g. log the user out
      //     return Promise.reject(err);
      //   }
      // }

      // return Promise.reject(err);
    }
  );
}

// export function setupAxios(axios: any) {
//   axios.defaults.headers.Accept = 'application/json'
//   axios.interceptors.request.use(
//     (config: {headers: {Authorization: string}}) => {
//       const auth = getAuth()
//       if (auth && auth.accessToken) {
//         config.headers.Authorization = `Bearer ${auth.accessToken}`
//       }

//       return config
//     },
//     (err: any) => Promise.reject(err)
//   )
// }

export {getAuth, setAuth, removeAuth, scheduleTokenRefresh, AUTH_LOCAL_STORAGE_KEY}
