import React, {UIEvent, useMemo, useEffect, useState, useRef, useCallback} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {
  MaterialReactTable,
  MRT_Cell,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  type MRT_RowVirtualizer,
  useMaterialReactTable,
  MRT_EditActionButtons,
} from 'material-react-table'
import {Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import {QueryClient, QueryClientProvider, useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {Box, Typography} from '@mui/material'
//Date Picker Imports
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import {DatePickerInput, DatesRangeValue} from '@mantine/dates'
import SetLineChart from '../charts/SetLineChart'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useAuth } from '../../../../app/modules/auth'

interface CatalogSetsTableProps {
  className?: string // Make it optional
}

interface SetsCatalogProps {
  className?: string // Make it optional
}

type SetsApiResponse = {
  data: Array<Sets>
  meta: {
    totalRowCount: number
  }
}

type Sets = {
  set_num: string
  name: string
  theme: string
  subtheme: string
  upc_barcode: string
  ean_barcode: string
  num_parts: number
  item_number: string
  boid: string
  dim_length_imperial: number
  dim_width_imperial: number
  dim_height_imperial: number
  weight_imperial: number
  dim_length_metric: number
  dim_width_metric: number
  dim_height_metric: number
  weight_metric: number
  date_released: Date
  date_retired: Date
  rrp_usd: number
  rrp_gbp: number
  rrp_cad: number
  rrp_eur: number
  img_url: string
  minifig_count: number
  galleryImages: string[];
}

type Minifigures = {
  quantity: number
  img_url: string
  fig_num: string
  bricklink_num: string
  name: string
  num_parts: number
}

type UserSetInv = {
  user_id: number
  set_num: string
  quantity: number
  price_paid: number
  date_acquired: Date
  collection: string
  location: string
  condition: string
}

interface SelectedSetSubset {
  set_num: string;
  name: string;
  upc_barcode: string;
  date_retired: Date;
}

interface ImageGalleryItem {
  original: string;
  thumbnail: string;
  // ... any other properties you want to include
}

const AccordionManager = ({ selectedSet }: { selectedSet: SelectedSetSubset | null }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    const accordionBody = document.getElementById('kt_accordion_1_body_1');

    if (accordionBody) {
      const handleShow = () => setIsAccordionOpen(true);
      const handleHide = () => setIsAccordionOpen(false);

      accordionBody.addEventListener('show.bs.collapse', handleShow);
      accordionBody.addEventListener('hide.bs.collapse', handleHide);

      return () => {
        accordionBody.removeEventListener('show.bs.collapse', handleShow);
        accordionBody.removeEventListener('hide.bs.collapse', handleHide);
      };
    }
  }, []);

  return (
    <div data-bs-parent='#kt_accordion_1'>
      <div className='accordion-body'>
        {isAccordionOpen && (
          <SetLineChart 
            upc_barcode={selectedSet ? selectedSet.upc_barcode : ''} 
            type="BUY_BOX_SHIPPING"
            date_retired={selectedSet ? selectedSet.date_retired : new Date()} // passing the date_retired value
          />
        )}
      </div>
    </div>
  );
}

const SetsCatalog: React.FC<SetsCatalogProps> = ({className}) => {

  const [selectedSet, setSelectedSet] = useState<{ set_num: string, name: string, theme: string, subtheme: string, upc_barcode: string, ean_barcode: string,  img_url: string, num_parts: number, item_number: string, boid: string, dim_length_imperial: number, dim_width_imperial: number, dim_height_imperial: number, weight_imperial: number, dim_length_metric: number, dim_width_metric: number, dim_height_metric: number, weight_metric: number, minifig_count: number, date_released: Date, date_retired: Date, rrp_usd: number, rrp_gbp: number, rrp_cad: number, rrp_eur: number, } | null>(null);
  const [galleryImages, setGalleryImages] = useState<ImageGalleryItem[]>([]);

  const retiringSoonImageUrl = 'https://img.studfinder.app/RetiringSoon.png';
  const retiredImageUrl = 'https://img.studfinder.app/Retired.png';

  const [minifigures, setMinifigures] = useState<Minifigures[]>([]); // State to store minifigures

  const handleSetClick = async (set: Sets) => {
    try {
      // Fetch the images from the backend for the selected set
      const response = await fetch(`${process.env.REACT_APP_API_URL}/sets/${set.set_num}/images/`);
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const { images } = await response.json(); // Only read the body once
  
      const formattedGalleryImages: ImageGalleryItem[] = images.map((url: string) => ({
        original: url,
        thumbnail: url,
      }));
      setGalleryImages(formattedGalleryImages);
  
      // Fetch the minifigures for the selected set
      const minifigResponse = await fetch(`${process.env.REACT_APP_API_URL}/minifigures/${set.set_num}/`);
  
      if (!minifigResponse.ok) {
        throw new Error(`HTTP error! Status: ${minifigResponse.status}`);
      }
  
      const minifigData = await minifigResponse.json();
      setMinifigures(minifigData.data); // Assuming the API returns the minifigures array in data
  
      setSelectedSet({
        set_num: set.set_num,
        name: set.name,
        theme: set.theme,
        subtheme: set.subtheme,
        upc_barcode: set.upc_barcode,
        ean_barcode: set.ean_barcode,
        num_parts: set.num_parts,
        item_number: set.item_number,
        boid: set.boid,
        dim_length_imperial: set.dim_length_imperial,
        dim_width_imperial: set.dim_width_imperial,
        dim_height_imperial: set.dim_height_imperial,
        weight_imperial: set.weight_imperial,
        dim_length_metric: set.dim_length_metric,
        dim_width_metric: set.dim_width_metric,
        dim_height_metric: set.dim_height_metric,
        weight_metric: set.weight_metric,
        rrp_usd: set.rrp_usd,
        rrp_gbp: set.rrp_gbp,
        rrp_cad: set.rrp_cad,
        rrp_eur: set.rrp_eur,
        minifig_count: set.minifig_count,
        img_url: images[0],
        date_released: new Date(set.date_released),
        date_retired: new Date(set.date_retired),
      });
    } catch (error) {
      console.error((error as Error).message || error);
    }
  };

  useEffect(() => {
    if (selectedSet) {
    }
  }, [selectedSet]);

  // Helper function to determine if a set is retiring soon or already retired
  const getRetirementImage = () => {
    if (!selectedSet || !selectedSet.date_retired) return null;

    const today = new Date();
    const retirementDate = new Date(selectedSet.date_retired);
    const sixMonthsFromNow = new Date();
    sixMonthsFromNow.setMonth(today.getMonth() + 6);

    if (retirementDate < today) {
      return retiredImageUrl;
    } else if (retirementDate < sixMonthsFromNow) {
      return retiringSoonImageUrl;
    }

    return undefined;  // No image if not retiring soon or retired
  };

  const columns = useMemo<MRT_ColumnDef<Sets>[]>(
    () => [
      {
        accessorKey: 'thumbnail',
        enableClickToCopy: false,
        header: 'Thumbnail',
        size: 150,
        enableColumnFilter: false,
        Cell: ({ cell, row }) => {
          const setNum = row.original.set_num; // Use set number to construct image URL
          const imgUrl = `https://cdn.studfinder.app/${setNum}%2F${setNum}_image1.jpg`; // Construct the image URL
        
          return (
            <div onClick={() => handleSetClick(row.original)}>
              {imgUrl ? (
                <img 
                  src={imgUrl} 
                  onError={(e) => (e.currentTarget.style.display = 'none')} // Hide the image if it fails to load
                  height={50} 
                  loading="lazy"  // Lazy load images
                  data-bs-toggle="modal" 
                  data-bs-target="#kt_modal_2"
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <div /> // Return empty div if there's no image
              )}
            </div>
          );
        },
      },      
      {
        accessorKey: 'set_num',
        header: 'Set Number',
        size: 150,
        enableGlobalFilter: true,
        Cell: ({ cell, row }) => {
          const setNum = cell.getValue<string>()
          return (
            <div 
              data-bs-toggle="modal" 
              data-bs-target="#kt_modal_2" 
              onClick={() => handleSetClick(row.original)}
              style={{ cursor: 'pointer' }}
            >
              {setNum}
            </div>
          )
        },
      },      
      {
        accessorKey: 'name',
        header: 'Set Name',
        size: 150,
        enableGlobalFilter: true,
        Cell: ({ cell, row }) => {
          const setName = cell.getValue<string>()
          return (
            <div 
              data-bs-toggle="modal" 
              data-bs-target="#kt_modal_2" 
              onClick={() => handleSetClick(row.original)}
              style={{ cursor: 'pointer' }}
            >
              {setName}
            </div>
          )
        },
      },
      {
        accessorKey: 'theme',
        header: 'Theme',
        enableGlobalFilter: true,
        size: 120,
      },
      {
        accessorKey: 'rrp_usd',
        header: 'MSRP ($)',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value != null ? `$${value}` : ''; // Return empty string if null
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      {
        accessorKey: 'rrp_gbp',
        header: 'MSRP (£)',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value != null ? `£${value}` : ''; // Return empty string if null
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      {
        accessorKey: 'rrp_cad',
        header: 'MSRP (C$)',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value != null ? `C$${value}` : ''; // Return empty string if null
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      {
        accessorKey: 'rrp_eur',
        header: 'MSRP (€)',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value != null ? `€${value}` : ''; // Return empty string if null
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      {
        accessorKey: 'num_parts',
        Cell: ({ cell }) => {
          const value = cell.getValue<number>();
          return value != null ? value.toLocaleString() : ''; // Add null check
        },
        header: 'Pieces',
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        size: 150,
        muiFilterSliderProps: {
          marks: true,
          max: 12000, //custom max (as opposed to faceted max)
          min: 0, //custom min (as opposed to faceted min)
          step: 10,
        },
      },
      {
        accessorKey: 'minifig_count',
        header: 'Minifigures',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      {
        accessorFn: (row) => new Date(row.date_released), // Convert to Date for sorting and filtering
        id: 'date_released',
        header: 'Released',
        // filterFn: 'betweenInclusive',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const date = cell.getValue<Date>();
          return date ? date.toLocaleDateString() : 'N/A'; // Add null check
        },
        Header: ({column}) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({column}) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerInput
              type='range'
              clearable
              placeholder='Select Date Range'
              valueFormat='MM/DD/YY'
              onChange={(newValue: any) => {
                column.setFilterValue(newValue) // Set the filter value for this column
              }}
              value={column.getFilterValue() as DatesRangeValue}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorFn: (row) => new Date(row.date_retired), // Convert to Date for sorting and filtering
        id: 'date_retired',
        header: 'Retired',
        // filterFn: 'betweenInclusive',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const date = cell.getValue<Date>();
          return date ? date.toLocaleDateString() : 'N/A'; // Add null check
        },
        Header: ({column}) => <em>{column.columnDef.header}</em>, //custom header markup
        Filter: ({column}) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerInput
              type='range'
              clearable
              placeholder='Select Date Range'
              valueFormat='MM/DD/YY'
              onChange={(newValue: any) => {
                column.setFilterValue(newValue) // Set the filter value for this column
              }}
              value={column.getFilterValue() as DatesRangeValue}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  )
  const tableContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
  const rowVirtualizerInstanceRef =
    useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null) //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })
  const [rowCount, setRowCount] = useState(0)
  const {data, fetchNextPage, isError, isFetching, isLoading, refetch} =
    useInfiniteQuery<SetsApiResponse>({
      queryKey: [
        'table-data',
        columnFilters, //refetch when columnFilters changes
        globalFilter, //refetch when globalFilter changes
        pagination.pageIndex, //refetch when pagination.pageIndex changes
        pagination.pageSize, //refetch when pagination.pageSize changes
        sorting, //refetch when sorting changes
      ],
      queryFn: async ({pageParam = 0}) => {
        const fetchURL = new URL(
          '/sets/',
          process.env.NODE_ENV === 'production'
            ? `${process.env.REACT_APP_API_URL}`
            : 'http://localhost:3000'
        )
        fetchURL.searchParams.set('page', `${pageParam + 1}`) // +1 because pageParam starts from 0 for the first page
        fetchURL.searchParams.set('size', `${pagination.pageSize}`)
        fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []))
        fetchURL.searchParams.set('search', globalFilter ?? '')
        fetchURL.searchParams.set('sort', JSON.stringify(sorting ?? []))

        const response = await fetch(fetchURL.href)
        const json = (await response.json()) as SetsApiResponse
        console.log('Fetched Data:', json)
        return json
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    })

  const flatData = useMemo(() => data?.pages.flatMap((page) => page.data) ?? [], [data])
  console.log(data)
  const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0
  const totalFetched = flatData.length

  const SetsCatalogTable = useMaterialReactTable({
    columns,
    data: flatData,
    enableEditing: false,
    enableGlobalFilter: true,
    editDisplayMode: 'modal',
    columnFilterDisplayMode: 'subheader',
    enableClickToCopy: false,
    enableRowSelection: false,
    enableRowVirtualization: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableRowPinning: false,
    enableRowActions: false,
    rowVirtualizerInstanceRef: rowVirtualizerInstanceRef, //get access to the virtualizer instance
    rowVirtualizerOptions: {overscan: 10},   
    initialState: {
      showColumnFilters: true,
      columnOrder: ['thumbnail','set_num', 'name', 'theme', 'rrp_usd', 'rrp_gbp'],
      //columnPinning: { left: ['set_number'] },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        muiTableHeadCellProps: {
          align: 'center', //change head cell props
        },
      },
      'mrt-row-numbers': {
        enableColumnOrdering: true, //turn on some features that are usually off
        enableResizing: true,
        muiTableHeadCellProps: {
          sx: {
            fontSize: '1.2rem',
          },
        },
      },
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: 'grid',
          margin: 'auto',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
        }}
      >
      <div>
          {/* <Typography>Pieces: {row.original.num_parts.toString()}</Typography> */}
          <Typography>Theme: {row.original.theme}</Typography>
        </div>
      </Box>),
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: {
      ref: tableContainerRef, //get access to the table container element
      sx: {maxHeight: '400px'}, //give the table a max height
      onScroll: (
        event: UIEvent<HTMLDivElement> //add an event listener to the table container element
      ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    muiToolbarAlertBannerProps: isError 
    ? {
        color: 'error',
        children: 'Error loading data',
      }
    : undefined,
    renderTopToolbarCustomActions:() => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    renderBottomToolbarCustomActions: () => (
      <Typography>
        Fetched {totalFetched} of {totalDBRowCount.toLocaleString()} total rows. Good boy! ˁ(⚆ᴥ⚆)ˀ
      </Typography>
    ),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
    },
    enablePagination: false,
    rowCount: rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    }
  );

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const {scrollHeight, scrollTop, clientHeight} = containerRefElement
        console.log('Scroll Values:', {scrollHeight, scrollTop, clientHeight})
        if (
          scrollHeight - scrollTop - clientHeight < 200 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          console.log('Fetching next page...')
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  )

  //scroll to top of table when sorting or filters change
  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0)
    } catch (error) {
      console.error(error)
    }
  }, [sorting, columnFilters, globalFilter])

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])


// Retrieve user ID from useAuth()
const { currentUser } = useAuth();
let userId: number | undefined;

// Convert currentUser?.id to a number if it's defined
if (currentUser?.id) {
  userId = Number(currentUser.id);

  if (isNaN(userId)) {
    console.error('Invalid user ID');
    userId = 0;  // Set a default value or handle the error appropriately
  }
} else {
  console.error('User ID is undefined');
}

const tableInvContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
  const rowInvVirtualizerInstanceRef =
    useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null) 

// Define the columns for the user inventory table
const inventoryColumns = useMemo<MRT_ColumnDef<UserSetInv>[]>(() => [
  {
    accessorKey: 'set_num',
    header: 'Set Number',
    enableEditing: false,  // Make 'Set Number' non-editable
    size: 100,
  },
  {
    accessorKey: 'quantity',
    header: 'Quantity',
    enableEditing: true,
    size: 100,
    // Add editing props for the quantity field with typed 'cell'
    muiEditTextFieldProps: ({ cell }: { cell: MRT_Cell<UserSetInv> }) => ({
      type: 'number',  // Set input type to number
      inputProps: { min: 1 },  // Set a minimum value of 1
    }),
  },
  {
    accessorKey: 'price_paid',
    header: 'Price Paid',
    Cell: ({ cell }) => `$${cell.getValue<number>()}`, // Ensure 2 decimal places
    size: 100,
    muiTableBodyCellEditTextFieldProps: {
      type: 'number',
    },
  },
  {
    accessorKey: 'date_acquired',
    header: 'Date Acquired',
    Cell: ({ cell }) => {
      const dateValue = cell.getValue<string>();
      return dateValue ? new Date(dateValue).toLocaleDateString() : 'N/A';
    },
    size: 150,
    muiTableBodyCellEditTextFieldProps: {
      type: 'date',
    },
  },
  {
    accessorKey: 'collection',
    header: 'Collection',
    size: 150,
    muiTableBodyCellEditTextFieldProps: {
      placeholder: 'Enter collection...',
    },
  },
  {
    accessorKey: 'location',
    header: 'Location',
    size: 150,
    muiTableBodyCellEditTextFieldProps: {
      placeholder: 'Enter location...',
    },
  },
  {
    accessorKey: 'condition',
    header: 'Condition',
    size: 150,
    muiTableBodyCellEditTextFieldProps: {
      placeholder: 'Enter condition...',
    },
  },
], []);


const { data: inventoryData, refetch: refetchInventory } = useQuery(
  ['userInventory', selectedSet?.set_num, userId],  // Keys for the query
  async () => {

    const token = localStorage.getItem('token');  // Retrieve the token from localStorage

    if (!token) {
      throw new Error('No token found');
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/sets/${selectedSet?.set_num}/inventory`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,  // Send the token in the Authorization header
        'Content-Type': 'application/json'
      }
    });
    
    if (!response.ok) {
      throw new Error('Failed to fetch inventory data');
    }

    return response.json() as Promise<UserSetInv[]>;
  },
  {
    enabled: !!selectedSet && !!userId,  // Only fetch when both selectedSet and userId are available
  }
);

const createInventoryRow = async (newRow: any, selectedSet: any, userId: number) => {
  const token = localStorage.getItem('token'); // Retrieve token from localStorage

  if (!token) {
    throw new Error('No token found');
  }

  // Set a default value of 1 for quantity if it's not provided
  const quantity = newRow?.quantity ?? 1;

  // Construct API request to create a new inventory row
  const response = await fetch(`${process.env.REACT_APP_API_URL}/users/sets/inventory`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      set_num: selectedSet?.set_num,
      quantity,  // Use the default value
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to create inventory row');
  }

  return response.json();
};



const SetInvtableContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
  const SetInvrowVirtualizerInstanceRef =
    useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null) //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
  const [SetInvcolumnFilters, SetInvsetColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const [SetInvglobalFilter, SetInvsetGlobalFilter] = useState('')
  const [SetInvsorting, SetInvsetSorting] = useState<MRT_SortingState>([])
  const [SetInvpagination, SetInvsetPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })
  const [SetInvrowCount, SetInvsetRowCount] = useState(0)
  // const {SetInvdata, SetInvfetchNextPage, SetInvisError, SetInvisFetching, SetInvisLoading, SetInvrefetch} =
  //   useInfiniteQuery<SetsApiResponse>({
  //     queryKey: [
  //       'table-data',
  //       SetInvcolumnFilters, //refetch when columnFilters changes
  //       SetInvglobalFilter, //refetch when globalFilter changes
  //       SetInvpagination.pageIndex, //refetch when pagination.pageIndex changes
  //       SetInvpagination.pageSize, //refetch when pagination.pageSize changes
  //       SetInvsorting, //refetch when sorting changes
  //     ],
  //     queryFn: async ({pageParam = 0}) => {
  //       const fetchURL = new URL(
  //         '/sets/',
  //         process.env.NODE_ENV === 'production'
  //           ? `${process.env.REACT_APP_API_URL}`
  //           : 'http://localhost:3000'
  //       )
  //       fetchURL.searchParams.set('page', `${pageParam + 1}`) // +1 because pageParam starts from 0 for the first page
  //       fetchURL.searchParams.set('size', `${pagination.pageSize}`)
  //       fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []))
  //       fetchURL.searchParams.set('search', globalFilter ?? '')
  //       fetchURL.searchParams.set('sort', JSON.stringify(sorting ?? []))

  //       const response = await fetch(fetchURL.href)
  //       const json = (await response.json()) as SetsApiResponse
  //       console.log('Fetched Data:', json)
  //       return json
  //     },
  //     getNextPageParam: (_lastGroup, groups) => groups.length,
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //   })

  const SetInvflatData = useMemo(() => inventoryData ?? [], [inventoryData]);
  const SetInvtotalDBRowCount = SetInvflatData.length;
  const SetInvtotalFetched = SetInvflatData.length;

const UserSetInvCatalogTable = useMaterialReactTable({
  columns: inventoryColumns,
  data: inventoryData || [],
  enableEditing: true,
  createDisplayMode: 'modal',
  positionCreatingRow: 'top',
  enableGlobalFilter: true,
  editDisplayMode: 'modal',
  columnFilterDisplayMode: 'subheader',
  enableClickToCopy: false,
  enableRowSelection: false,
  enableRowVirtualization: true,
  enableColumnOrdering: true,
  enableGrouping: true,
  enableColumnPinning: true,
  enableColumnResizing: true,
  enableRowPinning: false,
  enableRowActions: false,
  rowVirtualizerInstanceRef: SetInvrowVirtualizerInstanceRef, // get access to the virtualizer instance
  rowVirtualizerOptions: { overscan: 10 },
  initialState: {
    showColumnFilters: true,
    columnOrder: ['set_num', 'quantity', 'price_paid', 'date_acquired', 'collection', 'location'],
  },
  onCreatingRowSave: async ({ table, values }) => {
    try {
      // Ensure default values
      values.set_num = selectedSet?.set_num || '';
      values.quantity = values.quantity ?? 1;
  
      if (!selectedSet || !userId) {
        throw new Error('Set or User ID is missing');
      }
  
      await createInventoryRow(values, selectedSet, userId);
      refetchInventory();
      table.setCreatingRow(null);
    } catch (error) {
      console.error('Error creating inventory row:', error);
    }
  },
  renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
    <>
      <DialogTitle variant="h4">Add New Inventory</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
      >
        {internalEditComponents || <div>No fields available to edit.</div>}
      </DialogContent>
      <DialogActions>
        <MRT_EditActionButtons variant="text" table={table} row={row} />
      </DialogActions>
    </>
  ),
  
  onCreatingRowCancel: () => {
    // clear any validation errors
  },
  displayColumnDefOptions: {
    'mrt-row-actions': {
      muiTableHeadCellProps: {
        align: 'center', // change head cell props
      },
    },
    'mrt-row-numbers': {
      enableColumnOrdering: true, // turn on some features that are usually off
      enableResizing: true,
      muiTableHeadCellProps: {
        sx: {
          fontSize: '1.2rem',
        },
      },
    },
  },
  renderDetailPanel: ({ row }) => (
    <Box
      sx={{
        display: 'grid',
        margin: 'auto',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
      }}
    />
  ),
  manualFiltering: true,
  manualPagination: true,
  manualSorting: true,
  muiTableContainerProps: {
    ref: SetInvtableContainerRef, // get access to the table container element
    sx: { maxHeight: '400px' }, // give the table a max height
  },
  muiToolbarAlertBannerProps: isError
    ? {
        color: 'error',
        children: 'Error loading data.',
      }
    : undefined,
  renderTopToolbarCustomActions: ({ table }) => (
    <>
    {/* <h2 style={{ margin: '0 16px' }}>Table Title</h2> */}
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); // simplest way to open the create row modal with no default values
          // or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     // optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Add Inventory
      </Button>
    </>
  ),
  // renderBottomToolbarCustomActions: () => (
  //   // <Typography>
  //   //   Fetched {SetInvtotalFetched} of {SetInvtotalDBRowCount.toLocaleString()} total rows. Good boy! ˁ(⚆ᴥ⚆)ˀ
  //   // </Typography>
  // ),
  state: {
    // SetInvcolumnFilters,
    // SetInvglobalFilter,
    // SetInvisLoading,
    // SetInvpagination,
    // SetInvshowAlertBanner: isError,
    // SetInvshowProgressBars: isFetching,
    // SetInvsorting,
  },
  // SetInvenablePagination: false,
  // SetInvrowCount: rowCount,
  // SetInvonColumnFiltersChange: SetInvsetColumnFilters,
  // SetInvonGlobalFilterChange: SetInvsetGlobalFilter,
  // SetInvonPaginationChange: SetInvsetPagination,
  // SetInvonSortingChange: SetInvsetSorting,
});


  return (
    <div className={`card ${className} w-100`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 w-100'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Catalog - Sets</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
          { (data?.pages?.[0]?.meta?.totalRowCount ?? 0).toLocaleString() } Total Set(s) in Selection
          </span>
        </h3>
        {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Member
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 w-100'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <MaterialReactTable
            table={SetsCatalogTable}
          />
{/* Modal */}
<div className="modal bg-body fade" tabIndex={-1} id="kt_modal_2">
  <div className="modal-dialog modal-fullscreen">
    <div className="modal-content shadow-none">
      <div className="modal-header" style={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'white' }}>
      <h5 className="modal-title">
        {selectedSet ? (
            <>
              <span style={{ marginRight: '10px' }}>
                {`${selectedSet.set_num}: ${selectedSet.name}`}
              </span>
                <img 
                    src={selectedSet.img_url} 
                    alt={`${selectedSet.name} thumbnail`} 
                    style={{ height: '50px', marginRight: '10px' }} // You can adjust the style as needed
                />
            </>
        ) : (
            "Set Details"
        )}
    </h5><span style={{ marginRight: '30px' }}>
        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close">
          <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
          {/* <i className="ki-duotone ki-cross fs-1">
          <KTIcon iconName='cross' className='fs-1' />
          </i> */}
        </div></span>
      </div>
      <div className="modal-body">
  <div className="row g-5 align-items-stretch">
    {/* Set Overview Column */}
    <div className="col-lg-3 d-flex flex-column">
      <div className="card card-custom card-stretch shadow mb-5 d-flex flex-column">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3 className="card-title">Set Overview</h3>
          {selectedSet && getRetirementImage() && (
            <img 
              src={getRetirementImage() || ''}  // Ensure src is always a string
              alt="Retirement status" 
              style={{ height: '30px', marginLeft: 'auto' }}  // Adjust the height as needed
            />
          )}
        </div>
        <div className="card-body py-2">
          <table className="table table-row-dashed table-row-gray-300 gy-2">
            <tbody>
              {/* Set overview table content */}
              <tr>
                <td>Set Number</td>
                <td>{selectedSet?.set_num ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{selectedSet?.name ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Theme</td>
                <td>{selectedSet?.theme ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Subtheme</td>
                <td>{selectedSet?.subtheme ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Number of Pieces</td>
                <td>{selectedSet?.num_parts != null ? selectedSet.num_parts.toLocaleString() : '0'}</td>
              </tr>
              <tr>
                <td>Minifigures</td>
                <td>{selectedSet?.minifig_count != null ? selectedSet.minifig_count.toLocaleString() : '0'}</td>
              </tr>
              <tr>
                <td>Release Date</td>
                <td>{selectedSet?.date_released ? selectedSet.date_released.toLocaleDateString() : "N/A"}</td>
              </tr>
              <tr>
                <td>Retirement Date</td>
                <td>{selectedSet?.date_retired ? selectedSet.date_retired.toLocaleDateString() : "N/A"}</td>
              </tr>
              <tr>
                <td>Dimensions (Imperial)</td>
                <td>
                  {selectedSet && selectedSet.dim_length_imperial != null && selectedSet.dim_width_imperial != null && selectedSet.dim_height_imperial != null
                    ? `${selectedSet.dim_length_imperial} x ${selectedSet.dim_width_imperial} x ${selectedSet.dim_height_imperial} in`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Weight (Imperial)</td>
                <td>
                  {selectedSet?.weight_imperial != null
                    ? `${Math.floor(selectedSet.weight_imperial)} lb ${Math.round((selectedSet.weight_imperial % 1) * 16)} oz`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Dimensions (Metric)</td>
                <td>
                  {selectedSet?.dim_length_metric != null && selectedSet.dim_width_metric != null && selectedSet.dim_height_metric != null
                    ? `${selectedSet.dim_length_metric} x ${selectedSet.dim_width_metric} x ${selectedSet.dim_height_metric} cm`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Weight (Metric)</td>
                <td>
                  {selectedSet?.weight_metric != null
                    ? `${selectedSet.weight_metric} g`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>MSRP (USD)</td>
                <td>{selectedSet?.rrp_usd != null ? `$${selectedSet.rrp_usd}` : "N/A"}</td>
              </tr>
              <tr>
                <td>MSRP (GBP)</td>
                <td>{selectedSet?.rrp_gbp != null ? `£${selectedSet.rrp_gbp}` : "N/A"}</td>
              </tr>
              <tr>
                <td>MSRP (CAD)</td>
                <td>{selectedSet?.rrp_cad != null ? `C$${selectedSet.rrp_cad}` : "N/A"}</td>
              </tr>
              <tr>
                <td>MSRP (EUR)</td>
                <td>{selectedSet?.rrp_eur != null ? `€${selectedSet.rrp_eur}` : "N/A"}</td>
              </tr>
              <tr>
                <td>UPC Barcode</td>
                <td>{selectedSet?.upc_barcode ?? "N/A"}</td>
              </tr>
              <tr>
                <td>EAN Barcode</td>
                <td>{selectedSet?.ean_barcode ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Item Number</td>
                <td>{selectedSet?.item_number ?? "N/A"}</td>
              </tr>
              <tr>
                <td>BOID</td>
                <td>{selectedSet?.boid ?? "N/A"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>

    {/* Minifigures and other sections */}
    <div className="col-lg-9 d-flex flex-column">
      <div className="row g-5">
        <div className="col-lg-6">
          <div className="card card-custom card-stretch shadow mb-5">
            <div className="card-body">
              {selectedSet && galleryImages.length > 0 ? (
                <ImageGallery 
                  items={galleryImages} 
                  showNav={false}
                  thumbnailPosition="right"
                />
              ) : (
                <p>No images available.</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card card-custom card-stretch shadow mb-5">
            <div className="card-header">
              <h3 className="card-title">Inventory Management</h3>
            </div>
            {/* <div className="card-body"> */}
              <MaterialReactTable
                 table={UserSetInvCatalogTable}
              />
            {/* </div> */}
          </div>
        </div>
      </div>
                
      <div className="row g-5">
        <div className="col-lg-12">
          <div className="card card-custom card-stretch shadow mb-5">
            <div className="card-header">
              <h3 className="card-title">Minifigures</h3>
            </div>
            <div className="card-body" style={{ maxHeight: '250px', overflowY: 'auto' }}>
              {minifigures.length > 0 ? (
                <table className="table table-row-dashed table-row-gray-300 gy-2">
                  <thead>
                    <tr>
                      <th>Thumbnail</th>
                      <th>Quantity</th>
                      <th>Minifigure</th>
                      <th>Bricklink #</th>
                      <th>Name</th>
                      <th>Pieces</th>
                    </tr>
                  </thead>
                  <tbody>
                    {minifigures.map((minifig) => (
                      <tr key={minifig.fig_num}>
                        <td><img src={minifig.img_url} alt={minifig.name} height={50} /></td>
                        <td>{minifig.quantity}</td>
                        <td>{minifig.fig_num}</td>
                        <td>{minifig.bricklink_num}</td>
                        <td>{minifig.name}</td>
                        <td>{minifig.num_parts}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No minifigures found for this set.</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12 d-flex flex-column">
        <div className="card card-custom card-stretch shadow mb-5">
          <div className="card-header">
            <h3 className="card-title">Parts</h3>
          </div>
          <div className="card-body">Parts coming soon!</div>
        </div>
      </div>
    </div>
  {/* </div>
</div> */}


  {/* 
      <div className="card card-custom card-stretch-33 shadow mb-5">
          <div className="card-header">
              <h3 className="card-title">Height 33.333%</h3>
          </div>
          <div className="card-body">
              Lorem Ipsum is simply dummy text
          </div>
          <div className="card-footer">
              Footer
          </div>
      </div>

      <div className="card card-custom card-stretch-33 shadow mb-5">
          <div className="card-header">
              <h3 className="card-title">Height 33.333%</h3>
          </div>
          <div className="card-body">
              Lorem Ipsum is simply dummy text
          </div>
          <div className="card-footer">
              Footer
          </div>
      </div>
  </div> */}
{/* </div> */}

<div className='accordion' id='kt_accordion_1'>
<div className='accordion-item'>
  <h2 className='accordion-header' id='kt_accordion_1_header_1'>
    <button
      className='accordion-button fs-4 fw-bold collapsed'
      type='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_accordion_1_body_1'
      aria-expanded='false'
      aria-controls='kt_accordion_1_body_1'
    >
      Price History Chart
    </button>
  </h2>
  <div
    id='kt_accordion_1_body_1'
    className='accordion-collapse collapse'
    aria-labelledby='kt_accordion_1_header_1'
    data-bs-parent='#kt_accordion_1'
  >
    <div className='accordion-body'>
      <AccordionManager selectedSet={selectedSet} />
    </div>
  </div>
</div>
<div className='accordion-item'>
        <h2 className='accordion-header' id='kt_accordion_1_header_2'>
          <button
            className='accordion-button fs-4 fw-bold collapsed'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_accordion_1_body_2'
            aria-expanded='false'
            aria-controls='kt_accordion_1_body_2'
          >
            Sales Volume Chart
          </button>
        </h2>
        {/* <div
          id='kt_accordion_1_body_2'
          className='accordion-collapse collapse'
          aria-labelledby='kt_accordion_1_header_2'
          data-bs-parent='#kt_accordion_1'
        >
          <div className='accordion-body'>
          <div className="row g-5">
        <div className="col-lg-4">
            <div className="card card-custom card-stretch shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Pictures Lightbox</h3>
                </div>
                <div className="card-body">
                  <ImageGallery items={galleryImages} />
                </div>
                <div className="card-footer">   
                </div>
            </div>
        </div>

        <div className="col-lg-4">
            <div className="card card-custom card-stretch-50 shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Minifigures Table</h3>
                </div>
                <div className="card-body">
                    Coming soon!
                </div>
                <div className="card-footer">
                    Footer
                </div>
            </div>

            <div className="card card-custom card-stretch-50 shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Inventory Management</h3>
                </div>
                <div className="card-body">
                    Coming soon!
                </div>
                <div className="card-footer">
                    Footer
                </div>
            </div>
        </div>

        <div className="col-lg-4">
            <div className="card card-custom card-stretch-33 shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Height 33.333%</h3>
                </div>
                <div className="card-body">
                Coming soon!
                </div>
                <div className="card-footer">
                  
                </div>
            </div>

            <div className="card card-custom card-stretch-33 shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Height 33.333%</h3>
                </div>
                <div className="card-body">
                    Coming soon!
                </div>
                <div className="card-footer">
                    Footer
                </div>
            </div>

            <div className="card card-custom card-stretch-33 shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Height 33.333%</h3>
                </div>
                <div className="card-body">
                    Coming soon!
                </div>
                <div className="card-footer">
                    
                </div>
            </div>
        </div>
      </div>
    </div>
  </div> */}
</div>
<div className='accordion-item'>
  <h2 className='accordion-header' id='kt_accordion_1_header_3'>
    <button
      className='accordion-button fs-4 fw-bold collapsed'
      type='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_accordion_1_body_3'
      aria-expanded='false'
      aria-controls='kt_accordion_1_body_3'
    >
      Coming soon!
    </button>
  </h2>
  <div
    id='kt_accordion_1_body_3'
    className='accordion-collapse collapse'
    aria-labelledby='kt_accordion_1_header_3'
    data-bs-parent='#kt_accordion_1'
  >
    <div className='accordion-body'>
    </div>
  </div>
            </div>
            {/* <div className="modal-footer">
              <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div> */}
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const queryClient = new QueryClient()

const CatalogSetsTable: React.FC<CatalogSetsTableProps> = () => (
  <QueryClientProvider client={queryClient}>
    <SetsCatalog /> {/* Pass className as a prop */}
  </QueryClientProvider>
)

export {CatalogSetsTable}
