import React from 'react';

type Props = {
  column: any;
};

const ColumnFilter: React.FC<Props> = ({ column }) => {
  const { filterValue, setFilter } = column;

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value || undefined);
  };

  return (
    <div className='mt-2'>
      <input
        type='text'
        value={filterValue || ''}
        onChange={handleFilterChange}
        className='form-control form-control-sm'
        placeholder='Filter column...'
      />
    </div>
  );
};

export { ColumnFilter };
