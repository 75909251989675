import React, { useState } from 'react';

type Props = {
  filter: any;
  setFilter: (value: any) => void;
};

const GlobalFilter: React.FC<Props> = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter || '');

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value || undefined;
    setFilter(newValue);
    setValue(newValue || '');
  };

  return (
    <div className='my-3'>
      <label htmlFor='globalFilter'></label>
      <input
        id='globalFilter'
        type='text'
        value={value}
        onChange={handleFilterChange}
        className='form-control'
        placeholder="Search..."
        title="Search..."
      />
    </div>
  );
};

export { GlobalFilter };
