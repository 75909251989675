import axiosInstance from './axiosInstance'
import {UserModel} from './_models'
import callApi from '../../../helpers/callApi'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_API_TOKEN_URL = `${API_URL}/auth/refresh`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string, cognitoToken?: string | undefined) {
  return callApi.post('/auth/login', {
    email,
    password,
    cognitoToken
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
  cognitoUserId?: string,
) {
  return axiosInstance.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
    cognito_user_id: cognitoUserId?.toString()
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axiosInstance.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axiosInstance.post<{ accessToken: string, user: UserModel}>(
    GET_USER_BY_API_TOKEN_URL,
    {
      accessToken: token,
    },
    {
      withCredentials: true,
    }
  )
}
