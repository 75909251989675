import * as Yup from 'yup'
import clsx from 'clsx'
import {useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { useState } from 'react';
import Link from '@mui/material/Link';
import { useAuth } from '../core/Auth';

const CodeSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .required('Code confirmation is required')
})

const initialValues = {
  verificationCode: ''
}

const SignUpConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)

  const resendVerificationCode = async () => {
    try {
      const { email } = location.state as  { email: string };
      await resendSignUpCode({ username: email });
    } catch(e) {
      console.log("Error resending code", e)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: CodeSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { email, goToLocation } = location.state as  { email: string, goToLocation: string };

        const { isSignUpComplete, nextStep } = await confirmSignUp({
          username: email,
          confirmationCode: values.verificationCode
        });

        if(isSignUpComplete && nextStep.signUpStep === "DONE") {
          // Set the empty user with just email to allow been redirect to the dashboard page
          // Once in dashboard the app will fetch the correct user data
          setCurrentUser({
            id: '',
            username: '',
            password: '',
            email,
            first_name: '',
            last_name: ''
          })
          setLoading(false);
          setSubmitting(false)
          return navigate(goToLocation, { replace: true });
        }
      } catch(e) {
        setStatus("Something went wrong with the verification process")
        setLoading(false);
        setSubmitting(false)
      }
    }
  })
  return (
    <>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Confirm registration</h1>
        <div className='text-gray-500 fw-semibold fs-6'>We sent you a verification code to your email</div>
      </div>
      {/* begin::Heading */}
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {formik.status && 
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        }

        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Verification code</label>
          <input
            placeholder='123456'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.verificationCode && formik.errors.verificationCode},
              {
                'is-valid': formik.touched.verificationCode && !formik.errors.verificationCode,
              }
            )}
            type='text'
            name='verificationCode'
            autoComplete='off'
          />
          {formik.touched.verificationCode && formik.errors.verificationCode && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.verificationCode}</span>
            </div>
          )}
        </div>

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Resend verification code{' '}
        <span
            onClick={() => resendVerificationCode()}
            className='link-primary'
            style={{ cursor: 'pointer' }} // Make it look clickable
          >
            Resend
        </span>
        {/* <Link to='/auth/registration' className='link-primary'>
          Resend
        </Link> */}
      </div>
    </>
  )
}

export default SignUpConfirmation;