import axios from 'axios';

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Set your base URL
  // Add any other Axios configuration options here
});

// Request interceptor for logging requests
axiosInstance.interceptors.request.use((request) => {
  return request;
});

// Response interceptor for logging responses
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;